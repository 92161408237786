import React, { PropsWithChildren } from 'react'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

type Props = PropsWithChildren & {
  title?: string
  description?: string
}

export const SEO = ({ title, description, children }: Props) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    twitterUsername,
  } = useSiteMetadata()

  const seoTitle = `${defaultTitle} ${title ? ` | ${title}` : ''}`

  const seo = {
    title: seoTitle,
    description: description || defaultDescription,
    // image: `${siteUrl}${image}`,
    // url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <link rel="icon" href="assets/favicon.png" />
      <script src="/env-config.js" />
      {children}
    </>
  )
}
