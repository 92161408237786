import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

type Props = {
  className?: string
}

const H1 = ({ children, className }: PropsWithChildren<Props>) => (
  <h1 className={classNames('text-[2rem] leading-[1.3] text-nav-item-text', className)}>
    {children}
  </h1>
)

const H2 = ({ children, className }: PropsWithChildren<Props>) => (
  <h2
    className={classNames(
      'text-[3rem] font-medium leading-[1.1] text-primary-navy-dark',
      className,
    )}
  >
    {children}
  </h2>
)

const H3 = ({ children, className }: PropsWithChildren<Props>) => (
  <h3 className={classNames('text-[2.25rem] font-medium leading-[1.1]', className)}>{children}</h3>
)

const H4 = ({ children, className }: PropsWithChildren<Props>) => (
  <h4 className={classNames('text-[1.625rem] font-medium leading-[1.1]', className)}>{children}</h4>
)

const H5 = ({ children, className }: PropsWithChildren<Props>) => (
  <h5
    className={classNames(
      'text-[1.25rem] font-bold leading-[1.1] text-primary-navy-dark',
      className,
    )}
  >
    {children}
  </h5>
)

const BodyM = ({ children, className }: PropsWithChildren<Props>) => (
  <p className={classNames('font-uniform-rnd text-xl leading-normal', className)}>{children}</p>
)

const BodyS = ({ children, className }: PropsWithChildren<Props>) => (
  <p className={classNames('font-uniform-rnd text-[0.9375rem] leading-normal', className)}>
    {children}
  </p>
)

const BodyXS = ({ children, className }: PropsWithChildren<Props>) => (
  <p className={classNames('font-uniform-rnd text-[0.6875rem] leading-tight', className)}>
    {children}
  </p>
)

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  BodyM,
  BodyS,
  BodyXS,
}
